import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { customerUpdateZevAppEnabledFlag } from "../../../../../../domain/portal/manager/contracts/Contract.Repository"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { Caption } from "../../../../../../uikit/typography/Typography"
import { useAppSelector } from "../../../../../hooks"
import { mediumRedColor, mediumRedLightColor } from "../../../../../Shared.Theme"
import { AxiosErrorDataType } from "../../../../../Shared.Utils"
import { selectSelectedContractData } from "../../../store/contractSelectorSlice"

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.grey[500]}`,
}))

const ZevAppActivation = () => {
  const { t } = useTranslation("settings")
  const { zevAppActive, contractId } = useAppSelector(selectSelectedContractData)
  const [toggleState, setToggleState] = useState(zevAppActive ?? false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [pendingState, setPendingState] = useState(false)
  const [error, setError] = useState<AxiosErrorDataType>()

  const { mutate: updateZevAppEnabledFlag, isLoading: isUpdating } = useMutation(
    ["updateZevAppEnabledFlag"],
    () => customerUpdateZevAppEnabledFlag(contractId, pendingState),
    {
      onSuccess: () => {
        setIsModalOpen(false)
      },
      onError: (error: AxiosError) => {
        setPendingState(toggleState)
        setIsModalOpen(false)
        setError(error as AxiosErrorDataType)
      },
    },
  )

  const handleToggleClick = () => {
    setPendingState(!toggleState)
    setIsModalOpen(true)
  }

  const handleConfirm = () => {
    setToggleState(pendingState)
    updateZevAppEnabledFlag()
  }

  const handleCancel = () => {
    setPendingState(toggleState ?? false)
    setIsModalOpen(false)
  }

  return (
    <>
      <ErrorAlert
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
      />
      <StyledBox>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h6" gutterBottom>
              {t("zevAppActivation.title")}
            </Typography>
            <Caption>{t("zevAppActivation.description")}</Caption>
          </Box>
          <Switch checked={toggleState} onChange={handleToggleClick} color="primary" />
        </Box>
      </StyledBox>
      <Dialog open={isModalOpen} onClose={handleCancel}>
        <DialogTitle variant="h4">
          {t(`zevAppActivation.${pendingState ? "removeAccess" : "giveAccess"}.confirmTitle`)}
        </DialogTitle>
        <DialogContent>
          {t(`zevAppActivation.${pendingState ? "removeAccess" : "giveAccess"}.confirmMessage`)}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirm}
            variant="contained"
            disabled={isUpdating}
            sx={{
              backgroundColor: mediumRedColor,
              "&:hover": { backgroundColor: mediumRedLightColor },
              "&:disabled": { backgroundColor: mediumRedLightColor },
              padding: "12px 18px",
            }}
          >
            {t(`zevAppActivation.${pendingState ? "removeAccess" : "giveAccess"}`)}
          </Button>
          <Button onClick={handleCancel} variant="outlined">
            {t("zevAppActivation.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ZevAppActivation
