import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../App.Store"
import { ProductType } from "../../../../data/generated-sources/openapi"

export interface ContractSelectorState {
  contractId: string
  contractTitle: string
  contractDesc: Array<string>
  contractProductType: ProductType
  zevAppActive?: boolean
}

export const initialContractSelectorState: ContractSelectorState = {
  contractId: "",
  contractTitle: "",
  contractDesc: [],
  contractProductType: ProductType.ZEV,
  zevAppActive: false,
}

export const contractSelectorSlice = createSlice({
  name: "contracts",
  initialState: initialContractSelectorState,
  reducers: {
    setSelectedContractData: (state, { payload }: PayloadAction<ContractSelectorState>) => {
      state.contractId = payload.contractId
      state.contractTitle = payload.contractTitle
      state.contractDesc = payload.contractDesc
      state.contractProductType = payload.contractProductType
      state.zevAppActive = payload.zevAppActive
    },
  },
})

export const { setSelectedContractData } = contractSelectorSlice.actions

const contractSelectorState = (state: RootState) => state.managerPortal.contractSelector
export const selectSelectedContractData = createSelector(contractSelectorState, (state) => state)

export default contractSelectorSlice.reducer
